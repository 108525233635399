import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import { DataSource, type VideoComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useStringValue } from '../mixer-hocs/use-string-value.ts';

export const Video: FunctionComponent<VideoComponent> = (props) => {
  const src = useStringValue({
    // This string type check is temporary, until we backfill all Video components.
    value:
      typeof props.src === 'string' ? { type: DataSource.Direct, value: props.src } : props.src,
  });
  if (!src) {
    return null;
  }

  return (
    <div className={componentClassName(props)}>
      <div className="video-embed-container">
        <iframe src={src} allowFullScreen />
      </div>
    </div>
  );
};
